import * as Sentry from '@sentry/gatsby'

Sentry.init({
	dsn: 'https://24427b85a6d620dbb2a5fc00454a0f03@o4507725147537408.ingest.de.sentry.io/4507731089293392',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

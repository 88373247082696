import type { GatsbyBrowser } from 'gatsby'

import 'normalize.css'

import '@styles/global.scss'

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps }) => {
	const { pathname } = routerProps.location

	// If pathname starts with "/blog/", retain scroll position
	return !pathname.startsWith('/news/');
}
